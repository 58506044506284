body {
  background: #eeeeee;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 60px;
  padding-top: 60px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  border-radius: 10px;
  padding: 16px 40px 16px 40px;
  background: #2328eb;
  color: #ffffff;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.cnpj {
  font-size: 30px;
  margin-right: 30px;
}

.messageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

li {
  list-style: none;
  margin-bottom: 10px;
}

.textWrapper {
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
}

.titleWrapper {
  text-align: center;
  margin-bottom: 50px;
}

.message {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}
